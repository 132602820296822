import React from "react";
import { Button, Container, Typography, Box } from "@mui/material";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import JobListings from "./JobListings";
import SignUpForm from "./SignUpForm";
import LoginForm from "./LoginForm";

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Container maxWidth="md" style={{ textAlign: 'center', marginTop: '50px' }}>
                <Typography variant="h2" component="h1" gutterBottom>
                  Welcome to the SearchForDev Job Board
                </Typography>
                <Typography variant="h6" component="p" gutterBottom>
                  Make your start in the software development industry.
                </Typography>
                <Box mt={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    component={Link}
                    to="/jobs"
                  >
                    Get Started
                  </Button>
                </Box>
              </Container>
            }
          />
          <Route path="/jobs" element={<JobListings />} />
          <Route path="/signup" element={<SignUpForm />} />
          <Route path="/login" element={<LoginForm />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
