import React, { useState, useEffect, useCallback, Suspense, lazy } from "react";
import axios from "axios";
import MenuAppBar from "./MenuAppBar";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  TextField,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Autocomplete,
  CircularProgress,
  Popover,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { toDate } from "date-fns-tz";
import truncate from "html-truncate";
import companyLogo from "./company.png";
import locationLogo from "./location.png";
const JobDialog = lazy(() => import("./JobDialog"));

function JobListings() {
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);

  // Temporary filter states
  const [keyword, setKeyword] = useState("");
  const [location, setLocation] = useState("");
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [sortBy, setSortBy] = useState("date_posted");
  const [workType, setWorkType] = useState([]);

  // Search state (only updates when search button is clicked)
  const [searchParams, setSearchParams] = useState({
    keyword: "",
    location: "",
    minSalary: "",
    maxSalary: "",
    sortBy: "date_posted",
    workType: [],
  });

  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [open, setOpen] = useState(false);
  const [salaryAnchorEl, setSalaryAnchorEl] = useState(null);

  const handleJobHidden = (jobId) => {
    setJobs((prevJobs) => prevJobs.filter((job) => job._id !== jobId));
  };

  const loadJobs = useCallback((pageNum, params) => {
    setLoading(true);
    const workTypeParams = params.workType
      .map((type) => `work_type=${encodeURIComponent(type)}`)
      .join("&");

    // Get the token from localStorage
    const token = localStorage.getItem("token");
    console.log("Retrieved token:", token); // Add this line

    // Set up the headers
    const headers = token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {};
    console.log("Request headers:", headers); // Add this line

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/jobs?page=${pageNum}&limit=12&keyword=${params.keyword}&location=${params.location}&min_salary=${params.minSalary}&max_salary=${params.maxSalary}&sort_by=${params.sortBy}&${workTypeParams}`,
        { headers } // Include the headers in the request
      )
      .then((response) => {
        console.log("Raw response data:", response.data); // Log raw data

        // Ensure each job has an _id field
        const jobsWithId = response.data.jobs.map((job) => {
          if (!job._id) {
            console.warn("Job missing _id:", job); // Log any job missing _id
          }
          return {
            ...job,
            _id:
              job._id ||
              job.id ||
              `temp_${Math.random().toString(36).substr(2, 9)}`,
          };
        });

        console.log("Processed jobs:", jobsWithId); // Log processed jobs

        setJobs(jobsWithId);
        setTotalPages(response.data.total_pages);
        setHasNext(response.data.has_next);
        setHasPrev(response.data.has_prev);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job listings:", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Error data:", error.response.data);
          console.error("Error status:", error.response.status);
          console.error("Error headers:", error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error request:", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error message:", error.message);
        }
        setLoading(false);
      });
  }, []); // Empty dependency array

  // Load jobs on page change or when search parameters change
  useEffect(() => {
    loadJobs(page, searchParams);
  }, [page, searchParams, loadJobs]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (location.length >= 2) {
        fetchLocationSuggestions(location);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [location]);

  // Handle the search button click
  const handleSearch = () => {
    setSearchParams({
      keyword,
      location,
      minSalary,
      maxSalary,
      sortBy,
      workType,
    });
    setPage(1); // Reset to the first page
  };

  const handleNextPage = () => {
    if (hasNext) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (hasPrev) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleWorkTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setWorkType(typeof value === "string" ? value.split(",") : value);
  };

  const handleJobClick = useCallback((job) => {
    console.log("Selected job:", job);
    setSelectedJob(job);
    setOpen(true);
  }, []);

  const handleApplyClick = (event, jobLink) => {
    event.stopPropagation(); // Prevent the click from bubbling up to the card
    window.open(jobLink, "_blank"); // Open the job link in a new tab
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedJob(null);
  };

  // Function to fetch location suggestions from the API
  const fetchLocationSuggestions = async (query) => {
    if (query.length < 2) return; // Don't fetch suggestions if the query is too short

    setLocationLoading(true); // Show loading spinner

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/locations/suggestions`,
        {
          params: { query },
        }
      );
      setLocationSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    } finally {
      setLocationLoading(false); // Hide loading spinner
    }
  };

  const stripHtmlAndFormat = (htmlString) => {
    const text = htmlString.replace(/<[^>]+>/g, ""); // Strip HTML tags
    return text.replace(/\n/g, " "); // Replace newlines with spaces
  };

  const handleSalaryClick = (event) => {
    event.preventDefault();
    setSalaryAnchorEl(event.currentTarget);
  };

  const handleSalaryClose = () => {
    setSalaryAnchorEl(null);
  };

  const formatPostedDate = (dateString) => {
    // Parse the UTC date string
    const utcDate = toDate(dateString, { timeZone: "UTC" });

    // Get the current time in UTC
    const nowUtc = toDate(new Date(), { timeZone: "UTC" });

    // Calculate the distance
    return formatDistanceToNow(utcDate, { addSuffix: true, now: nowUtc });
  };

  const renderWorkType = (workType) => {
    const types = Array.isArray(workType) ? workType : [workType];
    return types.map((type, index) => (
      <Box
        key={index}
        mt={1}
        mr={0.5}
        sx={{
          display: "inline-block",
          padding: "0px 4px",
          border: "2px solid lightgrey",
          borderRadius: "4px",
          backgroundColor: "#efeaef",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {type}
        </Typography>
      </Box>
    ));
  };

  const renderWorkModel = (workModel) => {
    const models = Array.isArray(workModel) ? workModel : [workModel];
    return models.map((model, index) => (
      <Box
        key={index}
        mt={1}
        mr={0.5}
        sx={{
          display: "inline-block",
          padding: "0px 4px",
          border: "2px solid lightgrey",
          borderRadius: "4px",
          backgroundColor: "#f3f7f8",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {model}
        </Typography>
      </Box>
    ));
  };

  return (
    <Container maxWidth={false} style={{ maxWidth: "80%", marginTop: "0px" }}>
      <MenuAppBar />
      <Box
        mt={2}
        mb={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {/* First Row: Keyword, Location */}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={2.5}>
            <TextField
              label="Enter job title or keyword"
              placeholder="Keywords"
              variant="outlined"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2.5}>
            <Autocomplete
              freeSolo
              options={locationSuggestions}
              getOptionLabel={(option) => option}
              loading={locationLoading}
              onInputChange={(e, value) => setLocation(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enter a suburb, city or state"
                  placeholder="Location"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {locationLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        {/* Second Row: Salary, Job Type, Sort By */}
        <Grid container mb={1} spacing={2} mt={1} justifyContent="center">
          <Grid item xs={12} md={2}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel
                shrink={false}
                style={{
                  position: "absolute",
                  top: "-8px",
                  left: "14px",
                  backgroundColor: "white",
                  padding: "0 4px",
                }}
              ></InputLabel>
              <Button
                onClick={handleSalaryClick}
                variant="outlined"
                fullWidth
                size="small"
                sx={{
                  justifyContent: "flex-start",
                  textAlign: "left",
                  padding: "8.5px 14px",
                  borderColor: "rgba(0, 0, 0, 0.23)",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "1rem",
                  textTransform: "none",
                  "&:hover": {
                    borderColor: "rgba(0, 0, 0, 0.87)",
                    backgroundColor: "transparent",
                  },
                  height: "40px", // Match the height of other fields
                }}
              >
                Salary
              </Button>
            </FormControl>
            <Popover
              open={Boolean(salaryAnchorEl)}
              anchorEl={salaryAnchorEl}
              onClose={handleSalaryClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box sx={{ p: 2, width: 200 }}>
                <TextField
                  label="Min Yearly Salary"
                  placeholder="$0"
                  variant="outlined"
                  value={minSalary}
                  onChange={(e) => setMinSalary(e.target.value)}
                  type="number"
                  fullWidth
                  size="small"
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Max Yearly Salary"
                  placeholder="$100000"
                  variant="outlined"
                  value={maxSalary}
                  onChange={(e) => setMaxSalary(e.target.value)}
                  type="number"
                  fullWidth
                  size="small"
                />
              </Box>
            </Popover>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel>Job Type</InputLabel>
              <Select
                multiple
                value={workType}
                onChange={handleWorkTypeChange}
                label="Job Type"
                renderValue={(selected) => selected.join(", ")}
              >
                {[
                  "Full time",
                  "Part time",
                  "Contract/Temp",
                  "Casual/Vacation",
                  "Intern",
                ].map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={workType.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              select
              label="Sort By"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              variant="outlined"
              fullWidth
              size="small"
            >
              <MenuItem value="date_posted">Most Recent</MenuItem>
              <MenuItem value="relevance">Most Relevant</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              fullWidth
              style={{ height: "40px" }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={4}>
        {jobs.map((job) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={job.job_link}>
            <Card
              variant="outlined"
              onClick={() => handleJobClick(job)}
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Custom shadow
                borderRadius: "12px", // Rounded corners
                cursor: "pointer",
              }}
            >
              <CardContent
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    component="div"
                    mb={1}
                    sx={{ fontSize: "1.2rem" }}
                  >
                    {job.title}
                  </Typography>

                  <Box
                    mt={0.5}
                    mr={0.5}
                    sx={{
                      display: "inline-flex", // Use inline-flex to prevent full-width stretching
                      alignItems: "center", // Vertically center the items
                      padding: "2px 4px",
                      border: "2px solid lightgrey",
                      borderRadius: "4px",
                      backgroundColor: "#f3f7f8",
                    }}
                  >
                    <Box
                      component="img"
                      src={companyLogo}
                      alt="Company Logo"
                      sx={{
                        width: 18,
                        height: 18,
                        marginRight: 0.8, // Space between image and text
                      }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {job.company}
                    </Typography>
                  </Box>

                  {/* Location display */}
                  <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                    {Array.isArray(job.location) && job.location.length > 0 ? (
                      <>
                        <Box
                          sx={{
                            display: "inline-flex",
                            padding: "2px 4px",
                            border: "2px solid lightgrey",
                            borderRadius: "4px",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <Box
                            component="img"
                            src={locationLogo}
                            alt="Location Logo"
                            sx={{
                              width: 12,
                              height: 18,
                              marginRight: 1, // Space between image and text
                            }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            {job.location[0]}
                          </Typography>
                        </Box>
                        {job.location.length > 1 && (
                          <Box
                            sx={{
                              display: "inline-block",
                              padding: "0px 4px",
                              border: "2px solid lightgrey",
                              borderRadius: "4px",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              +{job.location.length - 1} more
                            </Typography>
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: "inline-block",
                          padding: "0px 4px",
                          border: "2px solid lightgrey",
                          borderRadius: "4px",
                          backgroundColor: "#f3f7f8",
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          {job.location}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <div>
                    {renderWorkType(job.work_type)}

                    {job.work_model &&
                      job.work_model !== "Not Found" &&
                      job.work_model !== "Not found" &&
                      job.work_model !== "" &&
                      renderWorkModel(job.work_model)}
                  </div>

                  {job.salary &&
                  job.salary !== "" &&
                  job.salary !== "Not found" &&
                  job.salary !== "No salary found" ? (
                    <div>
                      <Box
                        mt={1}
                        mr={0.5}
                        sx={{
                          display: "inline-block",
                          padding: "0px 4px", // Add some padding inside the box
                          border: "2px solid lightgrey", // Border around the box
                          borderRadius: "4px", // Rounded corners
                          backgroundColor: "#eaf3e6",
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          Salary: {job.salary}
                        </Typography>
                      </Box>
                    </div>
                  ) : null}
                  <Typography variant="body2" mt={2.5} paragraph>
                    {stripHtmlAndFormat(truncate(job.job_description, 350))}
                  </Typography>
                  {job.date_posted && job.date_posted !== "Unknown" && (
                    <Typography variant="body2" color="text.secondary">
                      Added {formatPostedDate(job.date_posted)}
                    </Typography>
                  )}
                </Box>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) => handleApplyClick(event, job.job_link)}
                    target="_blank"
                    fullWidth
                  >
                    Apply
                  </Button>
                </Box>
              </CardContent>
            </Card>
            {selectedJob && open && (
              <Suspense>
                <JobDialog
                  key={selectedJob._id}
                  job={selectedJob}
                  open={open}
                  onClose={handleClose}
                  onJobHidden={handleJobHidden}
                />
              </Suspense>
            )}
          </Grid>
        ))}
      </Grid>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: 5,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && jobs.length === 0 && (
        <Typography variant="h6" align="center" sx={{ mt: 5 }}>
          No jobs found. Try adjusting your search criteria.
        </Typography>
      )}
      {!loading && jobs.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            mt: 4,
            mb: 4,
            width: "100%",
          }}
        >
          <Box sx={{ width: "100px" }}>
            {" "}
            {/* Fixed width container for left button */}
            <Button
              variant="contained"
              onClick={handlePrevPage}
              disabled={!hasPrev}
            >
              Previous
            </Button>
          </Box>
          <Typography
            variant="body1"
            sx={{
              minWidth: "120px", // Adjust based on your longest possible text
              textAlign: "center",
            }}
          >
            Page {page} of {totalPages}
          </Typography>
          <Box sx={{ width: "100px" }}>
            {" "}
            {/* Fixed width container for right button */}
            <Button
              variant="contained"
              onClick={handleNextPage}
              disabled={!hasNext}
            >
              Next
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default JobListings;
